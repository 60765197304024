<template>
    <div v-if="row.item" class="o365-body-row" :data-o365-rowindex="rowIndex" ref="rowRef"
        :class="[{ 'active': row.item.current && activeRows, 'selected': row.item.isSelected, 'dirty': row.item.hasChanges && row.item.disableSaving}, rowClass, { 'd-none': row.isLoading, 'o365-group-row': row.item.o_groupHeaderRow || row.item.o_hasDetails }]"
        :style="[{ height: row.rowHeight + 'px', 'transform': 'translateY(' + row.pos + 'px)' }, rowStyle]">
        <template v-for="(col) in columns" :key="col.colId">
            <template v-if="!col.hide && col.pinned == pinned">
                <ScopedCell :x="col.order" :y="rowIndex" :getSelectionClass="getSelectionClass">
                    <template #default="{selectionClass}">
                        <ODataGridBodyCell :col="col" :row="row" :colIndex="col.order" :disabled="!dataGridControl.state.allowUpdate"
                            :selectionClass="selectionClass" :isTable="isTable" :features="gridFeatures"
                            :handleSelection="(pEvent) => handleSelection(pEvent, row)"
                            :is-active-edit-cell="activeEditLocation === `${container}_${col.order}_${rowIndex}`">
                            <template #editor>
                                <InlineCellEditor :ref="cmp => updateEditorRef(cmp, col.order + '_' + rowIndex)" :row="row?.item"
                                    :column="col" :gridContainer="viewportRef" :dataGridControl="dataGridControl"
                                    :rowIndex="rowIndex" />
                            </template>
                        </ODataGridBodyCell>
                    </template>
                </ScopedCell>
            </template>
        </template>
        <slot name="overlay" :row="row.item"></slot>
    </div>
</template>

<script lang="ts">

const ScopedCell = defineComponent({
    name: 'ScopedCell',
    props: ['getSelectionClass','x','y'],
    setup(props, ctx) {
        const selectionClass = computed(() => {
            return props.getSelectionClass(props.x, props.y);
        });
        const selValue = ref([]);
        watch(selectionClass, (newVal, oldVal) => {
            selValue.value = newVal;
        }, {
            immediate: true
        });
        return () => ctx.slots.default({selectionClass: selValue.value});
    }
});
</script>

<script setup lang="ts">
import type { ScrollItem } from 'o365.vue.composables.VirtualScroll.ts';
import type DataGridContorl from 'o365.controls.DataGrid.ts';
import type { Ref } from 'vue';

import { ODataGridBodyCell } from 'o365.vue.components.DataGrid.helpers.jsx';
import InlineCellEditor from 'o365.vue.components.DataGrid.InlineCellEditor.vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
// import { useRowHeightSync } from 'o365.vue.composable.DataGrid.Hover.ts';
import { computed, inject, onUpdated, ref, watch, defineComponent } from 'vue';

const props = defineProps<{
    row: ScrollItem,
    pinned?: 'left' | 'right', 
    rowIndex: number,
    rowClass: any,
    rowStyle: any,
    getSelectionClass: (x: number, y: number) => string | undefined,
    updateEditorRef: (cmp: any, loc: string) => void,
    container: string,
    activeEditLocation?: string | null,
    viewportRef: any,
    isTable?: boolean,
    activeRows?: boolean,
    rowHeightSync?: { key: string },
}>();

const dataGridControl = inject<Ref<DataGridContorl>>(dataGridControlKey);
if (dataGridControl == null || dataGridControl.value == null) {
    throw new Error('DataGridBodRow must be inside a DataGrid');
} 

const gridFeatures = computed(() => {
    const features: string[] = [];
    if (dataGridControl.value.hasRowDrag) {
        let allowDrag = true;
        if (props.row.item == null) {
            allowDrag = false;
        } else if (dataGridControl.value.props.rowDragOptions && dataGridControl.value.props.rowDragOptions.isRowDraggable) {
            allowDrag = dataGridControl.value.props.rowDragOptions.isRowDraggable(props.row.item);
        }
        if (allowDrag) {
            features.push('rowdrag');
        }
    }
    return features;
});

const columns = computed(() => {
    if (dataGridControl.value.isTable) {
        return dataGridControl.value.dataColumns.columns;
    }
    switch (props.pinned) {
        case 'left':
            return dataGridControl.value.dataColumns.leftColumns;
        case 'right':
            return dataGridControl.value.dataColumns.rightColumns;
        default:
            return dataGridControl.value.dataColumns.centerColumns;
    }
});

function handleSelection(pEvent: MouseEvent, pRow: ScrollItem) {
    // if (pRow.item) {
        // pRow.item.isSelected = !(pRow.item.isSelected ?? false);
    // }
    dataGridControl.value.handleScrollItemMultiSelect(pEvent, pRow);
    // data ? dataGridControl.selectionControl.onSelection(row) : null
}

// const rowRef = props.rowHeightSync
    // ? useRowHeightSync({
        // key: props.rowHeightSync.key,
        // index: props.rowIndex,
        // pinned: props.pinned
    // })
    // : ref(null);
    
const rowRef = props.rowHeightSync
    ? ref(null)
    : ref(null);

</script>
